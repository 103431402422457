import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, LinearProgress } from '@mui/material';

import API from 'app/API';
import TextField from 'app/ui-kit/components/TextField';
import useLoginCaptcha from 'pages/hooks/use-login-captcha';
import gtagEvent from 'utils/gtag-event';
import isOkResponse from 'utils/is-ok-response';

import useStyles from './styles';

interface Props {
    trial?: boolean;
    className?: string;
    showHeader?: boolean;
    redirectUrl?: string;
}

const Recovery: React.FC<Props> = ({ trial, redirectUrl, className, showHeader = true }) => {
    const { classes, cx } = useStyles();

    const history = useHistory();

    const [email, setEmail] = useState('');

    const [errorText, setErrorText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { captcha, getCaptcha, resetCaptcha } = useLoginCaptcha();

    const buildLink = useCallback(
        (link: string) => (trial ? `/trial/${link}`.replace(/\/\//g, '/').replace(/\/$/, '') : link),
        [trial]
    );

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await API.fetchWithToken(API.SEND_RECOVERY, {
                email: email,
                captcha: getCaptcha()
            });

            if (isOkResponse(response)) {
                gtagEvent('recovery-start');
                setIsSuccess(true);
                setErrorText('');
                if (redirectUrl) {
                    history.push(redirectUrl);
                }
            } else {
                resetCaptcha();
                setErrorText(response.data.error || 'Something went wrong');
            }
        } catch (error) {
            setErrorText(error.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }, [email, getCaptcha, history, redirectUrl, resetCaptcha]);

    const handleEnterKey = useCallback((event: React.KeyboardEvent) => event.code === 'Enter' && handleSubmit(), [
        handleSubmit
    ]);

    const handleChangeEmail = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
        []
    );

    if (isSuccess) {
        return (
            <div className={classes.root}>
                <div className={classes.textsContainer}>
                    <h4>{'Recover email sent'}</h4>
                    <p>{'Message with account activation instructions was sent to your email'}</p>
                </div>
                <div className={classes.buttonsContainer}>
                    <Button component={Link} to={buildLink('/')} color="primary">
                        {trial ? 'Back' : 'To home page'}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={cx(classes.root, className)}>
            {showHeader && (
                <div className={classes.textsContainer}>
                    <h1>{'Recover password'}</h1>
                    <p>
                        {'Go back to '}
                        <Link className={classes.link} to={buildLink('/login')}>
                            {'Sign in'}
                        </Link>
                    </p>
                </div>
            )}

            <div className={classes.fieldsContainer}>
                <TextField fullWidth name="email" label="Email" onChange={handleChangeEmail} />
                {errorText && <p className={classes.error}>{errorText}</p>}
            </div>

            {captcha}

            <div className={classes.buttonsContainer}>
                {isLoading && <LinearProgress variant="indeterminate" className={classes.loader} />}
                <Button
                    fullWidth
                    color="primary"
                    className={classes.submitButton}
                    variant="contained"
                    onClick={handleSubmit}
                    onKeyDown={handleEnterKey}
                    disabled={isLoading}
                >
                    {'Send'}
                </Button>
            </div>
        </div>
    );
};

export default Recovery;
