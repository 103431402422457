import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, LinearProgress } from '@mui/material';

import API from 'app/API';
import TextField from 'app/ui-kit/components/TextField';
import useStores from 'hooks/use-stores';
import OAuthButtonGroup from 'pages/components/OAuthButtonGroup';
import PasswordField from 'pages/components/PasswordField';
import { useHubSpotOauthRedirect } from 'pages/hooks/use-hubspot-redirection';
import useLoginCaptcha from 'pages/hooks/use-login-captcha';
import gtagEvent from 'utils/gtag-event';
import isOkResponse from 'utils/is-ok-response';

import useStyles from './styles';

interface Props {
    trial?: boolean;
    className?: string;
    showHeader?: boolean;
    showRecover?: boolean;
    redirectUrl?: string;
    enterprise?: boolean | ((backTo?: string, parameters?: object) => void);
    onSuccess?: () => void;
}

const LoginForm: React.FC<Props> = ({
    trial,
    className,
    showHeader = true,
    showRecover = true,
    redirectUrl,
    enterprise = false,
    onSuccess
}) => {
    const { classes, cx } = useStyles();
    const history = useHistory();

    const { userStore } = useStores();

    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errorText, setErrorText] = useState('');

    const [showTotp, setShowTotp] = useState(false);
    const [totp, setTotp] = useState('');
    const [totpVerificationCode, setTotpVerificationCode] = useState('');

    const { captcha, getCaptcha, resetCaptcha } = useLoginCaptcha();

    const resolvedRedirectUrl = redirectUrl || (trial ? '/trial' : '/search');

    const oAuthProps = useHubSpotOauthRedirect({ baseUrl: resolvedRedirectUrl, marketingAgreement: false });

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await API.fetchWithToken(API.LOGIN, {
                username: username,
                password: password,
                captcha: getCaptcha(),
                totpCode: totp ? parseInt(totp) : undefined,
                verificationCode: totp ? totpVerificationCode : undefined,
                trial
            });

            if (isOkResponse(response)) {
                if (response.data.result === 'totpCode required') {
                    setTotpVerificationCode(response.data.verificationCode);
                    setShowTotp(true);
                } else {
                    gtagEvent('login-success');
                    void userStore.bootstrap();
                    history.push(resolvedRedirectUrl);
                    onSuccess && onSuccess();
                }
            } else {
                gtagEvent('login-error');
                resetCaptcha();
                if (response.data.errorCode === 218) {
                    setErrorText('Invalid 2fa verification code');
                    setTotp('');
                    setShowTotp(false);
                    resetCaptcha();
                } else if (response.data.errorCode === 118) {
                    setErrorText(`User is not activated. \n Follow instructions in email or click RECOVER PASS`);
                } else {
                    setErrorText(response.data.error || 'Something went wrong');
                }
            }
        } catch (error) {
            gtagEvent('login-error');
            setErrorText(error.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }, [
        getCaptcha,
        history,
        onSuccess,
        password,
        resetCaptcha,
        resolvedRedirectUrl,
        totp,
        totpVerificationCode,
        trial,
        userStore,
        username
    ]);

    const handleChangeTotp = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = String(event.target.value);
        if (/^[0-9]{0,6}$/.test(value)) {
            setTotp(value);
        } else {
            event.preventDefault();
        }
    }, []);

    const handleEnterKey = useCallback((event: React.KeyboardEvent) => event.code === 'Enter' && handleSubmit(), [
        handleSubmit
    ]);

    const handleChangeUsername = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value),
        []
    );

    const buildLink = useCallback(
        (link: string) => (trial ? `/trial/${link}`.replace(/\/\//g, '/').replace(/\/$/, '') : link),
        [trial]
    );

    if (showTotp) {
        return (
            <div className={cx(classes.root, className)}>
                <div className={classes.textsContainer}>
                    <h1>{'Enter 2FA verification code'}</h1>
                </div>
                <div className={classes.fieldsContainer}>
                    <TextField
                        fullWidth
                        autoFocus
                        name="totp"
                        value={totp}
                        label="Verification code"
                        onKeyDown={handleEnterKey}
                        onChange={handleChangeTotp}
                    />
                </div>
                <div className={classes.buttonsContainer}>
                    {isLoading && <LinearProgress variant="indeterminate" className={classes.loader} />}
                    <Button
                        fullWidth
                        color="primary"
                        className={classes.submitButton}
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {'Verify'}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={cx(classes.root, className)}>
            {showHeader && (
                <div className={classes.textsContainer}>
                    <h1>{'Sign in'}</h1>
                    <p>
                        {'Don’t have an account? '}
                        <Link className={classes.link} to={buildLink('/signup')}>
                            {'Sign up'}
                        </Link>
                    </p>
                </div>
            )}

            <div className={classes.fieldsContainer}>
                <TextField fullWidth name="username" label="Username" onChange={handleChangeUsername} />
                <PasswordField onChange={setPassword} onSubmit={handleSubmit} skipValidation />
                {errorText && <p className={classes.error}>{errorText}</p>}
            </div>

            {captcha}

            <div className={classes.actionsContainer}>
                {isLoading && <LinearProgress variant="indeterminate" className={classes.loader} />}
                <div className={classes.buttonsContainer}>
                    <Button
                        fullWidth
                        color="primary"
                        className={classes.submitButton}
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {'Sign in'}
                    </Button>
                    {showRecover && (
                        <Button
                            fullWidth
                            color="primary"
                            className={classes.recoverButton}
                            variant="outlined"
                            component={Link}
                            to={buildLink('/recover-password')}
                            disabled={isLoading}
                        >
                            {'Recover Password'}
                        </Button>
                    )}
                </div>
                <div className={classes.oauthDivider}>{'OR'}</div>
                <OAuthButtonGroup
                    disabled={isLoading}
                    {...oAuthProps}
                    enterprise={trial ? false : enterprise}
                    className={classes.oauthButtonGroup}
                />
            </div>
        </div>
    );
};

export default LoginForm;
